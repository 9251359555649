import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../Layout";

const UxAuditSuccess = ({location, ...props}) => {
    const title = `Payment success`;

    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
            </Helmet>
            <section className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-height-1-1" data-uk-height-viewport="offset-top: true">
                <div className="uk-container uk-container-xsmall">
                    <div className="uk-padding">
                        <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                            <strong>&mdash;</strong> Payment successful
                        </p>
                        <h1 className="uk-margin-small-top">Thank you, order received!</h1>
                        <p>We've sent you an email with information about next steps.</p>
                        <p>Best,<br />Michael Lisboa</p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default UxAuditSuccess;
